exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".OrderBox_order-box .msg-not-available{color:var(--theme-stockAmount_OutOfStock_Color,#c00);font-weight:700;line-height:1.6em}.OrderBox_order-box .msg-on-demand{color:var(--theme-stockAmount_AvailableOnDemand_Color,#1f7bc9);font-weight:700;line-height:1.6em}.OrderBox_matrix-footer{text-align:right}.OrderBox_unavailable-matrix{text-align:left}.OrderBox_unavailable-matrix .OrderBox_unavailable-matrix-control{margin-top:1em;text-align:center}.OrderBox_unavailable-matrix .OrderBox_close-btn{font-size:var(--theme-button_Big_Size,22px);transform:translateZ(0)}.OrderBox_variants-edit-btn{border-left-color:var(--theme-borderColor,#e6e6e6);border-left-style:solid;border-left-width:1px;margin-left:1.3em;padding-left:1.3em}.OrderBox_loader{align-items:center;display:flex;flex-direction:column;padding:4em}.OrderBox_loader .OrderBox_spinner{margin-bottom:1em}.OrderBox_btn-placeholder,.OrderBox_text-placeholder{display:inline-block;vertical-align:middle}.OrderBox_hide{display:none}.OrderBox_variants-amount{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"order-box": "OrderBox_order-box",
	"orderBox": "OrderBox_order-box",
	"matrix-footer": "OrderBox_matrix-footer",
	"matrixFooter": "OrderBox_matrix-footer",
	"unavailable-matrix": "OrderBox_unavailable-matrix",
	"unavailableMatrix": "OrderBox_unavailable-matrix",
	"unavailable-matrix-control": "OrderBox_unavailable-matrix-control",
	"unavailableMatrixControl": "OrderBox_unavailable-matrix-control",
	"close-btn": "OrderBox_close-btn",
	"closeBtn": "OrderBox_close-btn",
	"variants-edit-btn": "OrderBox_variants-edit-btn",
	"variantsEditBtn": "OrderBox_variants-edit-btn",
	"loader": "OrderBox_loader",
	"spinner": "OrderBox_spinner",
	"btn-placeholder": "OrderBox_btn-placeholder",
	"btnPlaceholder": "OrderBox_btn-placeholder",
	"text-placeholder": "OrderBox_text-placeholder",
	"textPlaceholder": "OrderBox_text-placeholder",
	"hide": "OrderBox_hide",
	"variants-amount": "OrderBox_variants-amount",
	"variantsAmount": "OrderBox_variants-amount"
};